import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    IDailyTime, 
    IDailyTimeDetail, 
    IDailyEntries, 
    ITimeIdStatus,
    IDailyHours
} from "api/models/ITimeEngine";
import {
    getDailyTimeDetail,
    getDailyEntries,
    getDailyHours,
    getRemovedEntries,
    rejectDailyTime,
    approveDailyTime,
    getTimeStatuses,
    getNPActionTypes,
    saveDailyEntry,
    saveDailyHour,
    getActionTypes,
    getHomeStatusOptions,
    getPropertyAddresses
} from "api/timeEngineAPI";

interface ITimeEngineState {
    selectedDailyTime?: IDailyTime; 
    dailyTime?: any;
    nextRowNumber?: number;
    isLastRow?: boolean;
    dailyDetails: any;
    dailyEntries: any;
    dailyHours: any;
    dailyRemoved: any;
    editEntry: any;
    editHour: any;
    timeIdStatuses: ITimeIdStatus[];
    actionTypes: any;
    npActionTypes: any;
    propertyAddresses: any;
    updatedTime?: Partial<IDailyTime>;
}

const initialState: ITimeEngineState = {
    dailyTime: [],
    dailyDetails: [],
    dailyEntries: [],
    dailyHours: [],
    dailyRemoved: [],
    editEntry: {},
    editHour: {},
    actionTypes: [],
    npActionTypes: [],
    propertyAddresses: [],
    timeIdStatuses: []
}

export const fetchDailyTimeDetails = createAsyncThunk('timeEngine/fetchDailyTimeDetails', async (id: number) => {
    const detail = await getDailyTimeDetail(id);
    return detail;
});

export const fetchDailyEntries = createAsyncThunk('timeEngine/fetchDailyEntries', async (id: number) => {
    const detail = await getDailyEntries(id);
    return detail;
});

export const fetchDailyHours = createAsyncThunk('timeEngine/fetchDailyHours', async (id: number) => {
    const detail = await getDailyHours(id);
    return detail;
});

export const fetchRemovedEntries = createAsyncThunk('timeEngine/fetchRemovedEntries', async (id: number) => {
    const detail = await getRemovedEntries(id);
    return detail;
});

export const approveTime = createAsyncThunk('timeEngine/approveDailyTime', async (detail: Partial<IDailyTimeDetail>) => {
    return await approveDailyTime(detail);
});

export const rejectTime = createAsyncThunk('timeEngine/rejectDailyTime', async (detail: Partial<IDailyTimeDetail>) => {
    return await rejectDailyTime(detail);
});

export const approveHour = createAsyncThunk('timeEngine/approveDailyHour', async (detail: Partial<IDailyHours>) => {
    return await approveDailyTime(detail);
});

export const fetchTimeIdStatus = createAsyncThunk('actions/fetchTimeIdStatus', async (ids: number[]) => {
    let response = await getTimeStatuses({
        filter: { times: ids }
    });
    return response?.data || [];
});

export const saveEntry = createAsyncThunk('timeEngine/saveDailyEntry', async (detail: Partial<IDailyEntries>) => {
    return await saveDailyEntry(detail);
});

export const saveHours = createAsyncThunk('timeEngine/saveDailyHours', async (detail: Partial<IDailyHours>) => {
    return await saveDailyHour(detail);
});

export const fetchNPActionTypes = createAsyncThunk('actions/fetchNPActionTypes', async () => {
    let response = await getNPActionTypes();
    return response || {};
});

export const fetchActionTypes = createAsyncThunk('actions/fetchActionTypes', async () => {
    let response = await getActionTypes();
    return response || {};
});

export const fetchHomeStatus = createAsyncThunk('actions/fetchHomeStatus', async () => {
    let response = await getHomeStatusOptions();
    return response || [];
});

export const fetchPropertyAddresses = createAsyncThunk('actions/fetchPropertyAddresses', async (data: any) => {
    let response = await getPropertyAddresses(data);
    return response || {};
});

const actions = createSlice({
    name: "timeEngine",
    initialState,
    reducers: {
        selectDailyTime: (state, action) => {
            state.selectedDailyTime = action.payload;
        },
        setDailyTimes: (state, action) => {
            state.dailyTime = action.payload;
        },
        setDailyHours: (state, action) => {
            state.dailyHours = action.payload;
        },
        setNextRowNumber: (state) => {
            state.nextRowNumber = new Date().getTime();
        },
        setReachLastRow: (state, action) => {
            state.isLastRow = action.payload;
        },
        setEdit: (state, action) => {
            state.editEntry = action.payload;
        },
        setEditHours: (state, action) => {
            state.dailyHours = action.payload;
        },
        setTimeIdStatuses: (state, action) => {
            state.timeIdStatuses = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDailyTimeDetails.fulfilled, (state, action) => {
                state.dailyDetails = action.payload;
            })
            .addCase(fetchDailyEntries.fulfilled, (state, action) => {
                state.dailyEntries = action.payload;
            })
            .addCase(fetchDailyHours.fulfilled, (state, action) => {
                state.dailyHours = action.payload;
            })
            .addCase(fetchRemovedEntries.fulfilled, (state, action) => {
                state.dailyRemoved = action.payload;
            })
            .addCase(fetchTimeIdStatus.fulfilled, (state, action) => {
                state.timeIdStatuses = action.payload;
            })
            .addCase(fetchNPActionTypes.fulfilled, (state, action) => {
                state.npActionTypes = action.payload;
            })
            .addCase(approveTime.fulfilled, (state, action) => {
                state.dailyDetails = action.payload;
            })
            .addCase(rejectTime.fulfilled, (state, action) => {
                state.dailyDetails = action.payload;
            })
            .addCase(fetchActionTypes.fulfilled, (state, action) => {
                state.actionTypes = action.payload;
            })
            .addCase(fetchPropertyAddresses.fulfilled, (state, action) => {
                state.propertyAddresses = action.payload;
            })
    }
});

export const {
    selectDailyTime,
    setDailyTimes,
    setDailyHours,
    setReachLastRow,
    setNextRowNumber,
    setEdit,
    setEditHours,
    setTimeIdStatuses
} = actions.actions;
export default actions.reducer;