import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as atlas from "azure-maps-control";

import { IFireName, IFirePerimeter, IIntterraData, IProgramFire, IIntterraFire, IIrwinActiveIncidents, IClient, IFireDetail, IActiveFire, IGeoData, IGeotabEngine, IResClient } from "api/models";
import { getDispatchedFires, getEnginesDetails, getFireDetailsPanel, getMyProperties, getTodaysFires, getTodaysMonitors, getTodaysThreats, getYesterdaysFires, getYesterdaysMonitors, getYesterdaysThreats, IFireDataTable, saveSmokeCheck, checkMGUProps10miles } from 'api/sitMapAPI';

import demobilizedFire from "assets/img/legendSymbols/demobilized-fire.png";
import dispatchedFire from "assets/img/legendSymbols/dispatched-fire.png";
import monitoredFire from "assets/img/legendSymbols/monitored-fire.svg";
import nonDispatchedFire from "assets/img/legendSymbols/non-dispatchedfire.png";
import noteworthyFire from "assets/img/legendSymbols/noteworthy-fire.svg";
// import intterraFire from "assets/img/legendSymbols/IntterraFireIcon.png";
import activeEngine from "assets/img/mapSymbols/ActiveFireEngine.png";
import firePerim from "assets/img/legendSymbols/firePerimeter.png";
import threatPerim from "assets/img/legendSymbols/threatPerimeter.png";
import halfMileBuffer from "assets/img/legendSymbols/halfMileBuffer.png";
import mileBuffer from "assets/img/legendSymbols/mileBuffer.png";
import threeMileBuffer from "assets/img/legendSymbols/threeMileBuffer.png";
import zipCodeBorder from "assets/img/legendSymbols/zipCodeBorder.png";
import { pif_comm_enrolled, pif_comm_not_enrolled, pif_res_enrolled, pif_res_not_enrolled, pif_agri_enrolled, pif_agri_not_enrolled, pif_mgu } from "assets/img";
import { getGeotabEngines } from "api/commonAPI";
import { ISitMapEngine } from "api/models/ISitMapEngine";

interface ISitmapState {
    programFires: IProgramFire[];
    //Store all active fires, including program, notebook, and monitored fire
    //programfire - Dispached / Non-Dispached / Demobilized: properties.program = true and properties.wdsStatus = 1 / 2 / 3
    //noteworthyfire: properties.program = false and properties.noteworthy = true
    //monitoredfire: properties.program = false and properties.noteworthy = false
    allActiveFires?: IActiveFire;
    activeFires: IActiveFire;
    selectedFire?: IProgramFire;
    dispatchedFires: IFireName[];
    legendItems: { text: string, imgSrc?: string, color?: string }[];
    ClientsByFire?: IClient[];
    selectedClients: any[];
    availableClients: any[];
    selectedSitmapLayerIds?: string[];
    selectedFiremapLayerIds?: string[];
    intterraFires?: IIntterraFire;
    activeIncidents?: IIrwinActiveIncidents;
    engines?: IGeoData<IGeotabEngine>;
    activeEngine?: ISitMapEngine;
    timeFrameOptions: { text: string, value: string }[];
    timeFrame: string;
    //fire map page
    blIntterraFire?: boolean;
    wdsFirePerimeter?: atlas.data.FeatureCollection;//
    intterraFirePerimeter?: IFirePerimeter;
    intterraEvacutionZone?: IIntterraData;
    policyHolders?: IFireDataTable[];
    allPolicyHolders?: IFireDataTable[];
    highLightPolicyholder?: IFireDataTable;
    zipCodes?: {};
    eventPoints?: {};
    irPoints?: {};
    eventLines?: {};
    wdsFireDetail?: IFireDetail;
    enginesByFire?: [];
    fireHistory?: [];
    fireMapLegendItems: { text: string, imgSrc?: string, color?: string }[];
    didSmokeCheckSave: boolean;
    fireDetailsPanel?: any[];
    todayFireCount : number;
    yesterdayFireCount: number;
    todayMonitorCount: number;
    yesterdayMonitorCount: number;
    todayThreatCount: number;
    yesterdayThreatCount: number;
    sitMapEngines: ISitMapEngine[];
    myProperties: any[];
    activeProperty?: any;
    rightPanel: { isOpen: boolean, content: string };
    baseMap: string;
    windowSize: number;
    mguPids: number[]
}

const initialState: ISitmapState = {
    programFires: [],
    allActiveFires: {},
    activeFires: {},
    dispatchedFires: [],
    selectedClients: [],
    availableClients: [],
    // intterraFires: {},
    legendItems: [
        { text: 'Engine', imgSrc: activeEngine },
        // { text: 'Inactive Engine', imgSrc: inactiveEngine }, //bug#29170
        // { text: 'Intterra Fire Data', imgSrc: intterraFire },
        { text: 'Dispatched Fire', imgSrc: dispatchedFire },
        { text: 'Non-Dispatched Fire', imgSrc: nonDispatchedFire },
        { text: 'Demobilized Fire', imgSrc: demobilizedFire },
        { text: 'Noteworthy Fire', imgSrc: noteworthyFire },
        { text: 'Monitored Fire', imgSrc: monitoredFire },
        { text: 'Extreme', color: '#D84432' },
        { text: 'High', color: '#E18A46' },
        { text: 'Moderate', color: '#FFFC72' },
        { text: 'Low', color: '#6CA243' },
    ],
    activeIncidents: {},
    engines: {},
    // activeEngine: {},
    wdsFirePerimeter: undefined,
    // intterraFirePerimeter: {},
    // intterraEvacutionZone: {},
    policyHolders: [],
    highLightPolicyholder: undefined,
    zipCodes: {},
    eventPoints: {},
    irPoints: {},
    eventLines: {},
    wdsFireDetail: {},
    // blIntterraFire: false,
    enginesByFire: [],
    fireHistory: [],
    timeFrameOptions: [
        { text: '24 hours', value: '24' },
        { text: '7 days', value: '168' },
        { text: '2 weeks', value: '336' },
    ],
    timeFrame: window.innerWidth > 768 ? '168' : '24',
    fireMapLegendItems: [
        { text: 'Fire Perimeter', imgSrc: firePerim },
        { text: 'Threat Perimeter', imgSrc: threatPerim },
        { text: '.5 Mile Buffer', imgSrc: halfMileBuffer },
        { text: '1 Mile Buffer', imgSrc: mileBuffer },
        { text: '3 Mile Buffer', imgSrc: threeMileBuffer },
        { text: 'Outer Buffer', imgSrc: zipCodeBorder },
        { text: 'Zip Codes', imgSrc: zipCodeBorder },
        { text: 'Engine', imgSrc: activeEngine },
        { text: 'Residential Enrolled', imgSrc: pif_res_enrolled },
        { text: 'Commercial Enrolled', imgSrc: pif_comm_enrolled },
        { text: 'Agricultural Enrolled', imgSrc: pif_agri_enrolled },
        { text: 'Residential Not Enrolled', imgSrc: pif_res_not_enrolled },
        { text: 'Commercial Not Enrolled', imgSrc: pif_comm_not_enrolled },
        { text: 'Agricultural Not Enrolled', imgSrc: pif_agri_not_enrolled },
        { text: 'MGU', imgSrc: pif_mgu },
    ],
    didSmokeCheckSave: false,
    todayFireCount : -1,
    yesterdayFireCount: -1,
    todayMonitorCount: -1,
    yesterdayMonitorCount: -1,
    todayThreatCount: -1,
    yesterdayThreatCount: -1,
    sitMapEngines: [],
    myProperties: [],
    activeProperty: {},
    rightPanel: { isOpen: false, content: 'fireDetailsPanel'},
    baseMap: 'satellite_road_labels',
    windowSize: 1200,
    mguPids: []
}

export const fetchDispatchedFires = createAsyncThunk('sitMapActions/fetchDispatchedFires', async () => {
    let response = await getDispatchedFires();
    return response || [];
});

export const fetchEnginesDetails = createAsyncThunk('sitMapActions/fetchEnginesDetails', async () => {
    let response = await getEnginesDetails();
    let geotab = await getGeotabEngines();
    return { dbEngines: response, gtEngines: geotab };
})

export const fetchTodaysFire = createAsyncThunk('sitMapActions/fetchTodaysFire', async () => {
    let response = await getTodaysFires();
    return response || [];
});

export const fetchYesterdaysFire = createAsyncThunk('sitMapActions/fetchYesterdaysFires', async () => {
    let response = await getYesterdaysFires();
    return response || [];
});

export const fetchTodaysMonitors = createAsyncThunk('sitMapActions/fetchTodaysMonitors', async () => {
    let response = await getTodaysMonitors();
    return response || [];
});

export const fetchYesterdaysMonitors = createAsyncThunk('sitMapActions/fetchYesterdaysMonitors', async () => {
    let response = await getYesterdaysMonitors();
    return response || [];
});

export const fetchTodaysThreats = createAsyncThunk('sitMapActions/fetchTodaysThreats', async () => {
    let response = await getTodaysThreats();
    return response || [];
});

export const fetchYesterdaysThreats = createAsyncThunk('sitMapActions/fetchYesterdaysThreats', async () => {
    let response = await getYesterdaysThreats();
    return response || [];
});

export const fetchGeotabEngines = createAsyncThunk('sitMapActions/fetchGeotabEngines', async () => {
    let response = await getGeotabEngines();
    return response || [];
});

export const saveSmokeCheckAction = createAsyncThunk('sitMapActions/saveSmokeCheck', async (inputs: any) => {
    let response = await saveSmokeCheck(inputs);
    return response || {};
});
export const fetchFireDetailsPanel = createAsyncThunk('sitMapActions/fetchFireDetailsPanel', async (inputs: any) => {
    let response = await getFireDetailsPanel(inputs);
    return response || {};
});
export const fetchMyProperties = createAsyncThunk('sitMapActions/fetchMyProperties', async (inputs: any) => {
    let response = await getMyProperties(inputs);
    return response || {};
});
export const checkMGUProps = createAsyncThunk('sitMapActions/checkMGUProps', async (inputs: any) => {
    let response = await checkMGUProps10miles(inputs);
    return response || {};
});

//task:22663 22697 Instead, I extract clients from the table data
// export const fetchClientsByFire = createAsyncThunk('sitMapActions/fetchClientsByFire', async (fireId: number) => {
//     let response = await getClientByFireId(fireId);
//     return response || [];
// });

const sitMapActions = createSlice({
    name: "sitMapActions",
    initialState,
    reducers: {
        setProgramFires: (state, action) => {
            state.programFires = action.payload;
        },
        setActiveEngine: (state, action) => {
            state.activeEngine = action.payload;
        },
        selectProgramFire: (state, action) => {
            if (state.programFires?.length) {
                state.programFires.forEach(pf => {
                    if (pf.id === action.payload) {
                        pf.selected = true;
                        pf.isTop = true;
                    }
                    else {
                        pf.selected = false;
                        pf.isTop = false;
                    }
                });
            }
        },
        setSelectedFire: (state, action) => {
            state.selectedFire = action.payload;
        },
        //task#23468
        setAllActiveFires: (state, action) => {
            state.allActiveFires = action.payload;
        },
        setActiveFires: (state, action) => {
            state.activeFires = action.payload;
        },
        selectActiveFires: (state, action) => {
            if (state.activeFires?.features?.length) {
                state.activeFires.features.forEach(pf => {
                    if (!pf.properties) return
                    if (pf.properties?.fireId === action.payload?.fireId) {
                        pf.properties.selected = true;
                        pf.properties.isTop = action.payload?.isTop;
                    }
                    else {
                        pf.properties.selected = false;
                        pf.properties.isTop = false;
                    }
                });
            }
        },
        setRightPanel: (state, action) => {
            state.rightPanel = action.payload;
        },
        setActiveProperty: (state, action) => {
            state.activeProperty = action.payload;
        },
        setSelectedClients: (state, action) => {
            state.selectedClients = action.payload;
        },
        setAvailableClients: (state, action) => {
            state.availableClients = action.payload;
        },
        setSelectedSitmapLayerIds: (state, action) => {
            state.selectedSitmapLayerIds = action.payload;
        },
        setSelectedFiremapLayerIds: (state, action) => {
            state.selectedFiremapLayerIds = action.payload;
        },
        setPolicyholders: (state, action) => {
            state.policyHolders = action.payload;
        },
        setAllPolicyholders: (state, action) => {
            state.allPolicyHolders = action.payload;
        },
        setHighLightPolicyholder: (state, action) => {
            state.highLightPolicyholder = action.payload;
        },
        selectPolicyHolder: (state, action) => {
            if (state.policyHolders?.length) {
                state.policyHolders.forEach(ph => {
                    //fix bug #22632 > The unique identifier should be PID
                    if (ph.pid === action.payload) {
                        ph.selected = true;
                        ph.isTop = true;
                        //task:22341 need change state.highLightPolicyholder
                        state.highLightPolicyholder = { ...ph };
                    }
                    else {
                        ph.selected = false;
                        ph.isTop = false;
                    }
                });
            }
        },
        setFirePerimeters: (state, action) => {
            state.wdsFirePerimeter = action.payload;
        },
        // setIntterraEngines: (state, action) => {
        //     state.engines = action.payload;
        // },
        // setIntterraFires: (state, action) => {
        //     state.intterraFires = action.payload;
        // },
        setActiveIncidents: (state, action) => {
            state.activeIncidents = action.payload;
        },
        // setIntterraFirePerimeter: (state, action) => {
        //     state.intterraFirePerimeter = action.payload;
        // },
        // setIntterraEvacutionZone: (state, action) => {
        //     state.intterraEvacutionZone = action.payload;
        // },
        setZipCodes: (state, action) => {
            state.zipCodes = action.payload;
        },
        setEventPoints: (state, action) => {
            state.eventPoints = action.payload;
        },
        setIrPoints: (state, action) => {
            state.irPoints = action.payload;
        },
        setEventLines: (state, action) => {
            state.eventLines = action.payload;
        },
        // setFireType: (state, action) => {
        //     state.blIntterraFire = action.payload;
        // },
        setFireDetail: (state, action) => {
            state.wdsFireDetail = action.payload;
        },
        setClientsByFire: (state, action) => {
            state.ClientsByFire = action.payload;
        },
        setEnginesByFire: (state, action) => {
            state.enginesByFire = action.payload;
        },
        setFireHistory: (state, action) => {
            state.fireHistory = action.payload;
        },
        setTimeFrame: (state, action) => {
            //fix bug #25116
            const asyncGetDispatchedFires = async () => {
                let response = await getDispatchedFires(action.payload);
                setDispatchedFires(response ?? []);
            }
            setTimeout(() => {
                asyncGetDispatchedFires()
            });
            state.timeFrame = action.payload;
        },
        setDispatchedFires: (state, action) => {
            state.dispatchedFires = action.payload
        },
        setSmokeCheckSave: (state, action) => {
            state.didSmokeCheckSave = action.payload
        },
        setBaseMap: (state, action) => {
            state.baseMap = action.payload
        },
        setWindowSize: (state, action) => {
            state.windowSize = action.payload
        },
        selectActiveEngine: (state, action) => {
            if(state.sitMapEngines?.length) {
                state.activeEngine = state.sitMapEngines.find((item) => item.engineName == action.payload);
            }
        }
    },
    extraReducers: builder => {
        builder
            //fetchDispatchedFires
            .addCase(fetchDispatchedFires.fulfilled, (state, action) => {
                state.dispatchedFires = action.payload;
            })
            //fetchGeotabEngines
            .addCase(fetchGeotabEngines.fulfilled, (state, action) => {
                state.engines = action.payload;
            })
            //save smoke check comment
            .addCase(saveSmokeCheckAction.fulfilled, (state, action) => {
                state.didSmokeCheckSave = true;
                window.location.href = "/smokecheck/" + state.wdsFireDetail?.fireId;
            })
            .addCase(fetchFireDetailsPanel.fulfilled, (state, action) => {
                state.fireDetailsPanel = action.payload;
            })
            .addCase(fetchMyProperties.fulfilled, (state, action) => {
                state.myProperties = action.payload;
            })
            .addCase(fetchTodaysFire.fulfilled, (state, action) => {
                state.todayFireCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(fetchYesterdaysFire.fulfilled, (state, action) => {
                state.yesterdayFireCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(fetchTodaysMonitors.fulfilled, (state, action) => {
                state.todayMonitorCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(fetchYesterdaysMonitors.fulfilled, (state, action) => {
                state.yesterdayMonitorCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(fetchTodaysThreats.fulfilled, (state, action) => {
                state.todayThreatCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(fetchYesterdaysThreats.fulfilled, (state, action) => {
                state.yesterdayThreatCount = Array.isArray(action.payload) ? 0 : action.payload;
            })
            .addCase(checkMGUProps.fulfilled, (state, action) => {
                state.mguPids = action.payload;
            })
            .addCase(fetchEnginesDetails.fulfilled, (state, action) => {
                var { dbEngines, gtEngines } = action.payload;
                const newEngines = dbEngines.map((eeng: any) => {
                    const coords = gtEngines.features?.find((e: any) => e.id == eeng.geotabDeviceId)?.geometry?.coordinates;
                    return {
                        ...eeng,
                        geotabCoords: coords
                    }
                });
                state.sitMapEngines = newEngines;
            })
            //fetchClientsByFire
            //task:22663 22697 Instead, I extract clients from the table data
            // .addCase(fetchClientsByFire.fulfilled, (state, action) => {
            //     state.ClientsByFire = action.payload;
            // })
            ;
    }
});

export const {
    setProgramFires,
    setAllActiveFires,
    setActiveFires,
    selectProgramFire,
    selectActiveFires,
    setSelectedFire,
    setSelectedClients,
    setAvailableClients,
    setSelectedSitmapLayerIds,
    setSelectedFiremapLayerIds,
    setPolicyholders,
    setAllPolicyholders,
    setHighLightPolicyholder,
    selectPolicyHolder,
    setFirePerimeters,
    setActiveEngine,
    // setIntterraEngines,
    // setIntterraFires,
    setActiveIncidents,
    // setIntterraFirePerimeter,
    // setIntterraEvacutionZone,
    selectActiveEngine,
    setZipCodes,
    setEventPoints,
    setEventLines,
    setIrPoints,
    // setFireType,
    setFireDetail,
    setActiveProperty,
    setClientsByFire,
    setEnginesByFire,
    setDispatchedFires,
    setFireHistory,
    setTimeFrame,
    setSmokeCheckSave,
    setRightPanel,
    setBaseMap,
    setWindowSize
} = sitMapActions.actions;
export default sitMapActions.reducer;