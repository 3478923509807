export enum LogLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export const EventName = {
    PAGE_VIEW: 'page_view',
    SELECT_ITEM: 'select_item',
    VIEW_ITEM: 'view_item',
    REVIEW_STATUS_CHANGE: 'review_status_change',
    ENGINE_TIME_APPROVED: 'engine_time_approved',
    ENGINE_TIME_REJECTED: 'engine_time_rejected',
    ENGINE_TIME_ENTRY_UPDATED: 'engine_time_entry_updated',
    CLIENT_TIME_APPROVED: 'client_time_approved',
    CLIENT_TIME_ENTRY_UPDATED:'client_time_entry_updated'
}