import { postJsonWithNoTrack, postJson, getJson } from "utils";
import { IResClient, IUSer, IPageViewTelemetry, IGeotabEngine, IGeoData } from "api/models";

export async function writeLog(message: string, level: string) {
    return postJsonWithNoTrack('/api/log', { message, level });
}

export async function writeEvent(name: string) {
    return postJsonWithNoTrack('/api/Trace', { name });
}

export async function writePageViewEvent(data: Partial<IPageViewTelemetry>) {
    return postJsonWithNoTrack('/api/Track', data);
}

export async function getResClients(query: any): Promise<IResClient[]> {
    return postJson('/api/clients', query).then(res => res?.data || []);
}

export async function getUserInfo(): Promise<IUSer> {
    return getJson('/me');
}

export async function getGeotabEngines(): Promise<IGeoData<IGeotabEngine>> {
    var result = await getJson(
        '/api/common/geotab/engines'
    );
    if (!result) result = {}
    return result;
}